//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModuleImages from '@/components/modules/ModuleImages'
import ModuleText from '@/components/modules/ModuleText'
import ModuleProjects from '@/components/modules/ModuleProjects'
import ModuleColors from '@/components/modules/ModuleColors'
import ModuleEmbed from '@/components/modules/ModuleEmbed'
import ModuleVideo from '@/components/modules/ModuleVideo'
export default {
  components: {
    ModuleImages,
    ModuleText,
    ModuleProjects,
    ModuleColors,
    ModuleEmbed,
    ModuleVideo,
  },
  props: {
    modules: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
